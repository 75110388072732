import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyD3tpd5-QED9trSa52JZPqqwUJH0y422G0",
    authDomain: "slypandasite.firebaseapp.com",
    projectId: "slypandasite",
    storageBucket: "slypandasite.firebasestorage.app",
    messagingSenderId: "802958752033",
    appId: "1:802958752033:web:f8b5ab0643e176a2afe660",
    measurementId: "G-HZB7KNRHD9"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);