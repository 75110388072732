import KaosImage from './images/kaos.png';
import LimitlessImage from './logo.png';
import WooImage from './images/woo.png';
import JteImage from './images/jte.png';
import CropagationImage from './logo.png';

export const gameData = {
    kaos: {
      title: 'Kaos: Last Stand',
      image: KaosImage, // Replace with your actual image path
      description: 'In this thrilling virtual reality game, you play as a spaceman tasked with defending a space station from waves of drones and robots. With immersive gameplay and a variety of challenging enemies to face, this VR game will keep you on the edge of your seat as you battle for survival in the depths of outer space. Whether you’re fending off drones that explode on impact or taking on terrifying robots armed with powerful weapons, every moment in this game is filled with heart-pounding action. So put on your VR headset and get ready to defend your space station from the ultimate alien threat!',
      buttons: [
        { label: 'Play on Pico 4', link: 'https://sidequestvr.com/app/12586/project-kaos'},
        { label: 'Play on Quest 2', link: 'https://sidequestvr.com/app/13013/kaos-last-stand' },
        { label: 'Play on Itch.io', link: 'https://sly-panda.itch.io/project-kaos' },
      ],
    },
    limitless: {
      title: 'Limitless',
      image: LimitlessImage, // Replace with your actual image path
      description: 'Limitless: Earth Defence was an arcade style asteroid shooter with a twist. Instead of the traditional asteroids game play of destroying asteroids before they destroy your ship, you defend a central point (the Earth) against the incoming objects. The destruction of either your ship or the Earth will result in the game ending. Unfortunately we havent updated this recently and it is no longer available on the play store.',
      buttons: [
        { label: 'Play on Android', link: 'https://play.google.com/store' },
      ],
    },
    window: {
        title: 'Window of Opportunity',
        image: WooImage, // Replace with your actual image path
        description: 'This is a live testing version of our Brackeys Jam 2020.2 entry and may contain bugs, if you want a smoother experience please play the version on itch.io available here: https://sly-panda.itch.io/window-of-opportinuty',
        buttons: [
          { label: 'Play on Itch.io', link: 'https://sly-panda.itch.io/window-of-opportinuty' },
        ],
      },
      essentials: {
        title: 'Just the Essentials',
        image: JteImage, // Replace with your actual image path
        description: 'A Game originally made in 48 hours for the GMTK Game Jam 2020, it has since received some updates and bug fixes and is available to play below or through itch.io. This is a live testing version and may contain bugs, if you want a smoother experience please play the version on itch.io available here: https://sly-panda.itch.io/just-the-essentials-actual',
        buttons: [
          { label: 'Play on Itch.io', link: 'https://sly-panda.itch.io/just-the-essentials-actual' },
        ],        
      },
      cropagation: {
        title: 'Cropagation',
        //image: CropagationImage, // Replace with your actual image path
        description: 'Cropagation is our most recent project that is currently in development, a version will be available for alpha testers as part of our testing program as soon as possible. Followed by a closed beta test at the conclusion of the alpha phase.  More information will be made available prior to the start of the testing phases.',
        buttons: [
          //{ label: 'Buy on Steam', link: 'https://store.steampowered.com' },
        ],        
      }
  };
  