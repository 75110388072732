import React from 'react';

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h1>Privacy Policy</h1>
    <p><strong>Effective Date:</strong> January 1, 2024</p>

    <p>
      At Sly Panda Interactive (slypandainteractive.co.uk), we respect your privacy. We do not collect, store, or process any personal data through our website.
    </p>

    <h2>No Data Collection</h2>
    <p>
      We do not use cookies, tracking technologies, or forms that collect personal information. Your visit to our site is entirely anonymous.
    </p>

    <h2>GDPR Compliance</h2>
    <p>
      Since we do not handle personal data, our practices comply fully with the General Data Protection Regulation (GDPR). Any personal data provided inadvertently is not stored.
    </p>

    <h2>Third-Party Links</h2>
    <p>
      Our website may include links to external sites. We are not responsible for the privacy practices or content of these sites.
    </p>

    <h2>Contact Us</h2>
    <p>
      If you have any questions about this policy, please contact us at{' '}
      <a href="mailto:support@slypandainteractive.co.uk">support@slypandainteractive.co.uk</a>.
    </p>

    <p>By using our website, you agree to this Privacy Policy.</p>
  </div>
);

export default PrivacyPolicy;
