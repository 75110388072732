import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ImperiumScans.css";

const resourceCategories = {
  "white": ["Metals", "Baobabs", "Silicon", "Space Oats", "Nuclear Waste"],
  "#40BF00": ["Petroleum", "Vis", "Jelly Beans", "Copper", "Tin", "Silver", "Titanium", "Psion Icicles"],
  "#007FFC": ["Diamond", "Quantumum", "Alien Bacteria", "Enriched Nuclear Material", "Laconia", "Plasma Crystals", "Gold"],
  "#D86D00": ["Energon", "Dark Matter", "Frozen Blob", "Adamantium", "Rubicite", "Platinum", "Ablution Crystals", "Bacta", "Fermium", "Atmospheric Disturbances", "Dunes", "Roosts", "Trobbles", "Astral Worms"],
  "#992cff": ["Thickened Atmosphere", "Stabilized Weather", "Breathtaking Vistas", "Magnetic Miracle", "Biogeneticists Playground"],
  "#FE2712": ["Goop"]
};

const getResourceColor = (resourceName) => {
  const normalizedName = resourceName.trim().toLowerCase();
  for (const [color, resources] of Object.entries(resourceCategories)) {
    if (resources.some(res => res.toLowerCase() === normalizedName)) {
      return color;
    }
  }
  return "white";
};

const ImperiumScans = () => {
  const [groupedData, setGroupedData] = useState({});
  const [ownersMapping, setOwnersMapping] = useState({});
  const [teamsMapping, setTeamsMapping] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch galaxy scans as before.
  useEffect(() => {
    fetch("https://imp.slypandainteractive.com/galaxies")
      .then((res) => res.json())
      .then((jsonData) => {
        const grouped = {};
        jsonData.forEach((item) => {
          const galaxy = item.galaxy ? item.galaxy.trim() : "Unknown";
          if (!item.resource_name) return;
          if (!grouped[galaxy]) {
            grouped[galaxy] = [];
          }
          grouped[galaxy].push({
            resource: item.resource_name.trim(),
            total: item.total_amount,
          });
        });

        const sortedGrouped = {};
        Object.keys(grouped)
          .sort((a, b) => a.localeCompare(b))
          .forEach((galaxy) => {
            grouped[galaxy].sort((a, b) => {
              const aColor = getResourceColor(a.resource);
              const bColor = getResourceColor(b.resource);
              if (aColor === "white" && bColor !== "white") {
                return -1;
              }
              if (bColor === "white" && aColor !== "white") {
                return 1;
              }
              return a.resource.localeCompare(b.resource);
            });
            sortedGrouped[galaxy] = grouped[galaxy];
          });
        setGroupedData(sortedGrouped);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  // Fetch the universe.json file and build a mapping from galaxy name to owningTeamID.
  useEffect(() => {
    fetch("/universe.json")
      .then((res) => res.json())
      .then((data) => {
        console.log("Universe JSON data:", data); // Debug log
        const mapping = {};
        // Assume actual galaxy objects are under the "galaxies" property.
        if (data.galaxies) {
          Object.values(data.galaxies).forEach((galaxyObj) => {
            if (galaxyObj.name && galaxyObj.owningTeamID) {
              mapping[galaxyObj.name.trim().toLowerCase()] = galaxyObj.owningTeamID;
            }
          });
        }
        console.log("Owners mapping:", mapping); // Debug log
        setOwnersMapping(mapping);
      })
      .catch((error) => {
        console.error("Error fetching universe data:", error);
      });
  }, []);

  // Fetch the teams.json file and build a mapping from team ID to team name.
  useEffect(() => {
    fetch("/teams.json")
      .then((res) => res.json())
      .then((data) => {
        console.log("Teams JSON data:", data); // Debug log
        const mapping = {};
        if (data.teams) {
          Object.values(data.teams).forEach((team) => {
            if (team.teamID && team.name) {
              mapping[team.teamID] = team.name;
            }
          });
        }
        console.log("Teams mapping:", mapping); // Debug log
        setTeamsMapping(mapping);
      })
      .catch((error) => {
        console.error("Error fetching teams data:", error);
      });
  }, []);

  const handleRowClick = (galaxy) => {
    navigate(`/scandetails/${encodeURIComponent(galaxy)}`);
  };

  useEffect(() => {
    if (!loading && window.jQuery) {
      const $table = window.jQuery("#myTable");
      if (!$table.hasClass("dataTable")) {
        $table.DataTable({
          paging: false,
          stateSave: true,
        });
      }
    }
  }, [loading, groupedData]);

  return (
    <div className="imperium-scans">
      <h1>Imperium Galaxy Scans</h1>
      {loading ? (
        <p>Loading data...</p>
      ) : (
        <table id="myTable" className="details-table">
          <thead>
            <tr>
              <th>Owner</th>
              <th>Galaxy</th>
              <th>Resources</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).length > 0 ? (
              Object.keys(groupedData).map((galaxy, index) => {
                const normalizedGalaxy = galaxy.trim().toLowerCase();
                const teamID = ownersMapping[normalizedGalaxy];
                const teamName = teamID && teamsMapping[teamID] ? teamsMapping[teamID] : `Team ${teamID}`;
                return (
                  <tr key={index} onClick={() => handleRowClick(galaxy)}>
                    <td className="owner-cell">
                      {teamID ? (
                        <img
                          src={`https://www.starsonata.com/images/team_flags/game/${teamID}`}
                          alt={teamName}
                          title={teamName}
                          style={{ maxWidth: "50px", maxHeight: "50px" }}
                        />
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="galaxy-cell">{galaxy}</td>
                    <td className="resource-cell">
                      {groupedData[galaxy].map((res, idx, arr) => (
                        <span
                          key={idx}
                          className="resource-item"
                          style={{ color: getResourceColor(res.resource) }}
                        >
                          {res.resource}: {res.total}
                          {idx < arr.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3">No data found.</td>
              </tr>
            )}
          </tbody>
          <br />
        </table>
      )}
    </div>
  );
};

export default ImperiumScans;
