import React, { useState, useEffect } from "react";
import "./ImperiumScans.css";

function Proddy() {
  const [data, setData] = useState(null);
  const [roster, setRoster] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([
      fetch("https://imp.slypandainteractive.com/proddy.php").then((res) => res.json()),
      fetch("roster.json").then((res) => res.json())
    ])
      .then(([dataJson, rosterJson]) => {
        setData(dataJson);
        setRoster(rosterJson);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading data...</p>;
  if (!data || !roster) return <p>No data available.</p>;

  // Case-insensitive matching of bot names to roster
  const getMainName = (botName) => {
    const lowerBot = botName.toLowerCase();
    for (let player of roster) {
      if (player.MainCharacterName.toLowerCase() === lowerBot ||
          player.AltCharacters.map(alt => alt.toLowerCase()).includes(lowerBot)) {
        return player.MainCharacterName;
      }
    }
    return null;
  };

  // Aggregate entries by main name (or bot name with an asterisk if missing)
  const aggregateByMain = (items) => {
    const aggregated = {};
    items.forEach(entry => {
      const mainName = getMainName(entry.bot_name);
      const key = mainName ? mainName : `${entry.bot_name} *`;
      aggregated[key] = (aggregated[key] || 0) + Number(entry.total_quantity);
    });
    return aggregated;
  };

  // Render a table for each resource, aggregating totals by main name
  const renderTable = (itemName, items) => {
    const aggregatedData = aggregateByMain(items);
    const rows = Object.entries(aggregatedData).sort((a, b) => b[1] - a[1]);
    return (
      <div key={itemName} style={{ textAlign: "center" }}>
        <h2>{itemName}</h2>
        <table
          className="details-table"
          style={{
            margin: "0 auto",
            border: "1px solid #ccc",
            borderCollapse: "collapse"
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>Main Name</th>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>Total Quantity</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(([name, total]) => (
              <tr key={name}>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{name}</td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{total.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
    );
  };

  // Render overall totals table
  const renderTotalsTable = () => {
    const overallTotals = {};
    const addData = (items) => {
      items.forEach(entry => {
        const mainName = getMainName(entry.bot_name);
        const key = mainName ? mainName : `${entry.bot_name} *`;
        overallTotals[key] = (overallTotals[key] || 0) + Number(entry.total_quantity);
      });
    };

    if (data.priority) {
      Object.keys(data.priority).forEach(itemName => addData(data.priority[itemName]));
    }
    if (data.others) {
      Object.keys(data.others).forEach(itemName => addData(data.others[itemName]));
    }
    const sortedTotals = Object.entries(overallTotals).sort((a, b) => b[1] - a[1]);
    return (
      <div key="totals" style={{ textAlign: "center" }}>
        <h2>Overall Totals</h2>
        <table
          className="details-table"
          style={{
            margin: "0 auto",
            border: "1px solid #ccc",
            borderCollapse: "collapse"
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>Main Name</th>
              <th style={{ border: "1px solid #ccc", padding: "8px" }}>Total Quantity</th>
            </tr>
          </thead>
          <tbody>
            {sortedTotals.map(([name, total]) => (
              <tr key={name}>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{name}</td>
                <td style={{ border: "1px solid #ccc", padding: "8px" }}>{total.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
      </div>
    );
  };

  return (
    <div className="scan-details imperium-scans">
      <h1>Imperium Proddy Data</h1>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "20px" }}>
        {data.priority && Object.keys(data.priority).map(itemName => renderTable(itemName, data.priority[itemName]))}
        {data.others && Object.keys(data.others).map(itemName => renderTable(itemName, data.others[itemName]))}
        {renderTotalsTable()}
      </div>
    </div>
  );
}

export default Proddy;
