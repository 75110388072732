// GameContentDynamic.js
import React from 'react';
import { useParams } from 'react-router-dom';
import ContentPageTemplate from './ContentPageTemplate';
import { gameData } from './gameData';

const GameContentDynamic = () => {
  // useParams gets the gameId from the URL (e.g., "kaos" or "limitless")
  const { gameId } = useParams();
  const game = gameData[gameId];

  // If no game data is found, show a not found message
  if (!game) return <div>Game not found</div>;

  return (
    <ContentPageTemplate
      title={game.title}
      image={game.image}
      description={game.description}
      buttons={game.buttons}
    />
  );
};

export default GameContentDynamic;
