import React, { useState, useEffect } from "react";
import { useLocation, Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import News from "./News";
import logo from "./logo.png";
import implogo from "./implogo.png";
import PrivacyPolicy from "./PrivacyPolicy";
import ScanDetails from "./ScanDetails";
import Proddy from "./Proddy";
import GameContentDynamic from "./GameContentDynamic";
import LoginModal from "./LoginModal";
import ImperiumScans from "./ImperiumScans";
import "./App.css";

function App() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [communityDropdownOpen, setCommunityDropdownOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();
  const isSpecial = location.pathname.startsWith('/starsonata') || location.pathname.startsWith('/scandetails') || location.pathname.startsWith('/proddy');

  // Conditionally load a CSS file based on the current route.
  useEffect(() => {
    // Remove an existing conditional stylesheet, if present.
    const existingLink = document.getElementById("conditional-css");
    if (existingLink) {
      existingLink.remove();
    }
    // Create a new link element.
    const link = document.createElement("link");
    link.id = "conditional-css";
    link.rel = "stylesheet";
    // Use a special CSS file for the "/imperium" page; otherwise, load the default.
    if (location.pathname.startsWith("/starsonata") || location.pathname.startsWith("/scandetails") || location.pathname.startsWith('/proddy')) { 
      link.href = "/specialNav.css"; 
    } else {
      link.href = "/defaultNav.css"; 
    }
    document.head.appendChild(link);
  }, [location.pathname]);

  useEffect(() => {
    if (isSpecial) {
      document.body.style.backgroundColor = "#313131";
    } else {
      document.body.style.backgroundColor = "white"; 
    }
  }, [isSpecial]);

  return (    
    <div>
      <nav className="navbar">
        {(location.pathname.startsWith("/starsonata") || location.pathname.startsWith("/scandetails")) ? (
          <div className="logo-container">
            <img src={implogo} alt="Logo" className="logo" />
          </div>
        ) : (
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
        )}

        <div className="nav-menu">
          {(location.pathname.startsWith("/starsonata") || location.pathname.startsWith("/scandetails")) ? (
            <>
              {/* <NavLink to="/starsonata" className="nav-link">
                HOME
              </NavLink> */}
              {/* Since authentication is disabled, remove LOGIN/LOGOUT buttons */}
            </>
          ) : (
            <>
              <NavLink to="/" className="nav-link" end>
                HOME
              </NavLink>
              <div
                className="dropdown"
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                <button className="nav-button">OUR GAMES ▼</button>
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <NavLink to="/game/kaos" className="dropdown-link">
                      KAOS LAST STAND
                    </NavLink>
                    <NavLink to="/game/window" className="dropdown-link">
                      WINDOW OF OPPORTUNITY
                    </NavLink>
                    <NavLink to="/game/essentials" className="dropdown-link">
                      JUST THE ESSENTIALS
                    </NavLink>
                    <NavLink to="/game/limitless" className="dropdown-link">
                      LIMITLESS: EARTH DEFENCE
                    </NavLink>
                    <NavLink to="/game/cropagation" className="dropdown-link">
                      CROPAGATION
                    </NavLink>
                  </div>
                )}
              </div>
              <div
                className="dropdown"
                onMouseEnter={() => setCommunityDropdownOpen(true)}
                onMouseLeave={() => setCommunityDropdownOpen(false)}
              >
                <button className="nav-button">COMMUNITY ▼</button>
                {communityDropdownOpen && (
                  <div className="dropdown-content">
                    <a
                      href="https://discord.gg/nk3kCR97Ny"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-link"
                    >
                      DISCORD
                    </a>
                  </div>
                )}
              </div>
              <NavLink to="/contact" className="nav-link">
                CONTACT
              </NavLink>
              <NavLink to="/privacy" className="nav-link">
                PRIVACY
              </NavLink>
              <NavLink to="/starsonata" className="nav-link">
                STAR SONATA
              </NavLink>
              {/* <NavLink to="/proddy" className="nav-link">
                PRODDY
              </NavLink> */}
            </>
          )}
        </div>
      </nav>

      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route
            path="/contact"
            element={<div>email: support@slypandainteractive.com</div>}
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/proddy" element={<Proddy />} />
          <Route path="/game/:gameId" element={<GameContentDynamic />} />
          <Route path="/starsonata" element={<ImperiumScans />} />
          <Route path="/scandetails/:galaxy" element={<ScanDetails />} />
        </Routes>
      </div>
      {showLogin && <LoginModal onClose={() => setShowLogin(false)} />}
    </div>
  );
}

export default App;
