import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="container-limited">
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <img src="/images/hero.png" alt="Sly Panda Logo" className="hero-logo" />
          <p className="text-lg mt-4 font-light">
            A software development studio based in the West of Scotland, specializing in game development and software engineering.
          </p>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about-section max-w-5xl mx-auto text-center">
        <h2 className="text-4xl font-bold">Who We Are</h2>
        <div className="about-text">
          <p className="mt-6 text-lg">
            <strong>Our Story</strong> <p></p> Born amidst the rugged beauty of the West of Scotland, our journey began as a spark of childhood wonder—ignited by the magic of video games and an early fascination with technology. From late nights exploring virtual worlds to the thrill of solving real-world problems with code, we evolved from passionate gamers into dedicated software engineers. Today, our creative spirit drives us to craft not only immersive gaming experiences but also sophisticated automation systems and innovative software solutions that make everyday tasks more efficient and inspiring.
          </p>
          <hr className="separator" />
          <p className="mt-6 text-lg">
            <strong>Our Mission</strong> <p></p>At the core of our work lies a deep commitment to merging creativity with cutting‑edge technology. We strive to build dynamic and captivating experiences across diverse platforms—from immersive mobile and VR games to powerful automation systems and enterprise software. Our mission is to leverage our passion for software engineering to design intuitive, impactful solutions that entertain, empower, and streamline digital interactions for individuals and businesses alike.
          </p>
          <hr className="separator" />
          <p className="mt-6 text-lg">
            <strong>Our Vision</strong> <p></p>We envision a future where software transcends traditional boundaries to become a transformative art form—reshaping how we connect, innovate, and live our lives. By combining our rich heritage in game development with expertise in broader software engineering disciplines, we aim to redefine interactive technology. Our vision is to create a world where every line of code opens new possibilities, forging a future where digital experiences, from immersive games to intelligent automation, spark inspiration and drive progress.
          </p>
        </div>
      </section>

      {/* Services */}
      <section className="services-container">
        <div className="service-card">
          <img src="/images/mobile.png" alt="Mobile Games" className="service-icon" />
          <h3 className="text-xl font-semibold mt-4">Mobile Games</h3>
          <p className="text-gray-600 mt-3 text-center">
            We have successfully launched multiple Android games, focusing on intuitive controls and addictive gameplay.
          </p>
        </div>
        <div className="service-card">
          <img src="/images/vr.png" alt="VR Experiences" className="service-icon" />
          <h3 className="text-xl font-semibold mt-4">VR Experiences</h3>
          <p className="text-gray-600 mt-3 text-center">
            We’ve explored immersive worlds through VR, delivering unique experiences for platforms like Quest 2 and Pico 4.
          </p>
        </div>
        <div className="service-card">
          <img src="/images/gamejam.png" alt="Game Jams" className="service-icon" />
          <h3 className="text-xl font-semibold mt-4">Game Jams</h3>
          <p className="text-gray-600 mt-3 text-center">
            We thrive in the fast-paced world of game jams, where creativity meets innovation under tight deadlines.
          </p>
        </div>
      </section>

      {/* Call to Action */}
      <section className="call-to-action">
        <h2 className="text-4xl font-bold">Join Us on Our Journey</h2>
        <p className="text-lg mt-6">
          Whether you're a gamer, developer, or investor, we invite you to be part of our story.
          Follow our updates, play our games, and witness firsthand the evolution of Sly Panda Interactive.
        </p>
        <a href="/contact">Get in Touch</a>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Sly Panda Interactive. All Rights Reserved.</p>
      </footer>
    </div>
    <br></br>

    </div>
  );
};

export default Home;
