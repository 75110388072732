import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./ImperiumScans.css"; // Use your special CSS

// Define your resource categories (adjust colors and names as needed)
const resourceCategories = {
  "white": ["Metals", "Baobabs", "Silicon", "Space Oats", "Nuclear Waste"],
  "#40BF00": ["Petroleum", "Vis", "Jelly Beans", "Copper", "Tin", "Silver", "Titanium", "Psion Icicles"],
  "#007FFC": ["Diamond", "Quantumum", "Alien Bacteria", "Enriched Nuclear Material", "Laconia", "Plasma Crystals", "Gold"],
  "#D86D00": ["Energon", "Dark Matter", "Frozen Blob", "Adamantium", "Rubicite", "Platinum", "Ablution Crystals", "Bacta", "Fermium", "Atmospheric Disturbances", "Dunes", "Roosts", "Trobbles", "Astral Worms"],
  "#992cff": ["Thickened Atmosphere", "Stabilized Weather", "Breathtaking Vistas", "Magnetic Miracle", "Biogeneticists Playground"],
  "#FE2712": ["Goop"]
};

const getResourceColor = (resourceName) => {
  for (const [color, names] of Object.entries(resourceCategories)) {
    if (names.includes(resourceName)) {
      return color;
    }
  }
  return "red"; // default color if not found
};

const ScanDetails = () => {
  const { galaxy } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  // Blueprint option states
  const [techMultiplier, setTechMultiplier] = useState(1);
  const [noBps, setNoBps] = useState(false);
  const [oatsBp, setOatsBp] = useState(false);
  const [drillsBp, setDrillsBp] = useState(false);
  const [tenDrills, setTenDrills] = useState(false);

  // Fetch scan details data for the given galaxy
  useEffect(() => {
    fetch(`https://imp.slypandainteractive.com/galaxy_details.php?galaxy=${encodeURIComponent(galaxy)}`)
      .then((res) => res.json())
      .then((data) => {
        setDetails(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching scan details:", error);
        setLoading(false);
      });
  }, [galaxy]);

  // Initialize DataTables when table is rendered
  useEffect(() => {
    if (!loading && details.length > 0 && window.jQuery) {
      const $table = window.jQuery("#myTable");
      if (!$table.hasClass("dataTable")) {
        $table.DataTable({
          paging: false,
          stateSave: true,
        });
      }
    }
  }, [loading, details]);

  // Helper: parse resources string into an array of objects
  const parseResources = (resourcesStr) => {
    if (!resourcesStr) return [];
    return resourcesStr.split(", ").map((res) => {
      const [name, amount] = res.split(": ");
      return { name, amount: parseInt(amount, 10) };
    });
  };

  // Clipboard copy helper with fallback
  async function copyTextToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
        return;
      } catch (err) {
        console.error("Clipboard API failed, falling back:", err);
      }
    }
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = "0";
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      if (!successful) throw new Error("execCommand was unsuccessful");
    } catch (err) {
      console.error("Fallback: Unable to copy", err);
      throw err;
    }
    document.body.removeChild(textArea);
  }

  // Function to handle copying row data
  const copyToClipboard = async (row) => {
    // Build a data object using the updated (multiplied) values
    const data = {
      planetName: row.planet_name,
      resources: parseResources(row.resources),
      techLevels: {
        "Steel Foundry": Math.ceil(Number(row.steel_foundry) * techMultiplier),
        "Sentient Machine Learning": Math.ceil(Number(row.sentient_machine_learning) * techMultiplier),
        "Star Bottling Plant": Math.ceil(Number(row.star_bottling_plant) * techMultiplier),
        "Figurine Workshop": Math.ceil(Number(row.figurine_workshop) * techMultiplier),
        "Giant Space Still": Math.ceil(Number(row.giant_space_still) * techMultiplier),
      },
      techLevelMultiplier: techMultiplier,
      no_bps: noBps ? 1 : 0,
      oats_bp: oatsBp ? 1 : 0,
      drills_bp: drillsBp ? 1 : 0,
      ten_drills: tenDrills ? 1 : 0,
    };

    try {
      const response = await fetch("https://imp.slypandainteractive.com/generate_file.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      const text = await response.text();
      console.log("Generate file output:", text);
      await copyTextToClipboard(text);
      alert("Data copied to clipboard!");
    } catch (error) {
      console.error("Error copying data to clipboard:", error);
      alert("Error copying data to clipboard.");
    }
  };

  return (
    <div className="scan-details imperium-scans">
      <h1>{galaxy}</h1>
      
      {/* Blueprint Options: Radio Buttons for Tech Multiplier */}
      <div className="blueprint-options" style={{ margin: "20px 0", textAlign: "center" }}>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="techMultiplier"
            value="0.5"
            checked={techMultiplier === 0.5}
            onChange={(e) => setTechMultiplier(parseFloat(e.target.value))}
          />
          Tech 9
        </label>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="techMultiplier"
            value="1"
            checked={techMultiplier === 1}
            onChange={(e) => setTechMultiplier(parseFloat(e.target.value))}
          />
          Tech 16
        </label>
        <label style={{ marginRight: "10px" }}>
          <input
            type="radio"
            name="techMultiplier"
            value="1.5"
            checked={techMultiplier === 1.5}
            onChange={(e) => setTechMultiplier(parseFloat(e.target.value))}
          />
          Tech 20
        </label>
      </div>
      
      {/* Blueprint Options: Checkboxes */}
      <div className="blueprint-options" style={{ display: "flex", justifyContent: "center", gap: "20px", marginBottom: "20px" }}>
        <label>
          <input type="checkbox" checked={noBps} onChange={(e) => setNoBps(e.target.checked)} />
          No BPs
        </label>
        <label>
          <input type="checkbox" checked={oatsBp} onChange={(e) => setOatsBp(e.target.checked)} />
          Oats BP
        </label>
        <label>
          <input type="checkbox" checked={drillsBp} onChange={(e) => setDrillsBp(e.target.checked)} />
          Drills BP
        </label>
        <label>
          <input type="checkbox" checked={tenDrills} onChange={(e) => setTenDrills(e.target.checked)} />
          +10 Drills
        </label>
      </div>
      
      {loading ? (
        <p>Loading scan details...</p>
      ) : details.length > 0 ? (
        <table id="myTable" className="details-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Planet Name</th>
              <th>Gravity</th>
              <th>Temperature</th>
              <th>Atmosphere</th>
              <th>Suitability</th>
              <th>Base Slots</th>
              <th>Steel Foundry</th>
              <th>Sentient Machine Learning</th>
              <th>Star Bottling Plant</th>
              <th>Figurine Workshop</th>
              <th>Giant Space Still</th>
              <th>Resources</th>
              <th>Ruins</th>
              <th>Trade Bay</th>
            </tr>
          </thead>
          <tbody>
            {details.map((row, idx) => (
              <tr key={idx}>
                <td>{row.type}</td>
                <td>{row.planet_name}</td>
                <td>{row.gravity}</td>
                <td>{row.temperature}</td>
                <td>{row.atmosphere}</td>
                <td>{row.suitability}</td>
                <td>{row.base_slots}</td>
                <td>{Math.ceil(Number(row.steel_foundry) * techMultiplier)}</td>
                <td>{Math.ceil(Number(row.sentient_machine_learning) * techMultiplier)}</td>
                <td>{Math.ceil(Number(row.star_bottling_plant) * techMultiplier)}</td>
                <td>{Math.ceil(Number(row.figurine_workshop) * techMultiplier)}</td>
                <td>{Math.ceil(Number(row.giant_space_still) * techMultiplier)}</td>
                <td>
                  {parseResources(row.resources).map((resource, idx, arr) => (
                    <span key={idx} className="resource-item" style={{ color: getResourceColor(resource.name) }}>
                      {resource.name}: {resource.amount}
                      {idx < arr.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </td>
                <td>{row.ruins}</td>
                <td>
                  <button className="back-button"onClick={() => copyToClipboard(row)}>Copy Data</button>
                </td>
              </tr>
            ))}
          </tbody>
          <br></br>
        </table>
      ) : (
        <p>No scan details found for {galaxy}.</p>
      )}
      <div className="back-button-container" style={{ marginTop: "20px", textAlign: "center" }}>
        <button className="back-button" onClick={() => navigate("/starsonata")}>
          Back
        </button>
      </div>
    </div>
  );
};

export default ScanDetails;
