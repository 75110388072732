// ContentPageTemplate.js
import React from 'react';
import './ContentPageTemplate.css'; // We'll add CSS next

const ContentPageTemplate = ({ title, image, description, buttons }) => (
  <div className="content-page">
    <h1 className="content-title">{title}</h1>
    {image && <img src={image} alt={title} className="content-image" />}
    <p className="content-description">{description}</p>
    <div className="content-buttons">
      {buttons?.map((btn, idx) => (
        <a
          key={idx}
          href={btn.link}
          target="_blank"
          rel="noopener noreferrer"
          className="content-button"
        >
          {btn.label}
        </a>
      ))}
    </div>
  </div>
);

export default ContentPageTemplate;
